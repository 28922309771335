import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image, TrailguidePlugin } from "../../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`In the south of Spain a mountain range of over 3400m rises above the
mediterranean sea. The peaks of the Sierra Nevada are covered by snow even
during the summer. In the Winter the ski resort opens and you can ski high
above Granada with a view all the way to Africa. Below the snow line there
are dusty mountain bike trails all winter long.`}</p>
    <TrailguidePlugin content="lat=37.1384&lng=-3.4646" mdxType="TrailguidePlugin" />
    <p className="text-xs mb-16" align="center">
      <p>{`Open these trails directly in the `}<a parentName="p" {...{
          "href": "https://trailguide.net/?pos=12,37.1384,-3.4646"
        }}>{`web-app`}</a></p>
    </p>
    <p>{`Outside of the city you find several spots for biking. The tracks behind
La Zubia are the first you can reach and are easy to access. The mellow hills
offer nice and flowy tracks as well as a cool jump line, known from videos of
Greg Minaar or Rachel Atherton.`}</p>
    <br />
    <br />
    <h4>{`Majojos`}</h4>
    <p>{`Granada is located at the foothills of the Sierra Nevada. The warm climate
allows mountain biking all year long.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/2539"
      }}>{`here`}</a>{`.`}</p>
    <Image src="destinations/granada_8.jpg" mdxType="Image" />
    <br />
    <h4>{`La Zubia`}</h4>
    <p>{`The snowy mountains of the Sierra Nevada in the background, you can ride in
shorts 2500m lower down. The trails of La Zubia offer easy trails
for all skill levels.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/2545"
      }}>{`here`}</a>{`.`}</p>
    <Image src="destinations/granada_13.jpg" mdxType="Image" />
    <br />
    <h4>{`Greg Minaar's test track`}</h4>
    <p>{`Greg Minaar and other famous riders like Rachel Atherton shot videos on
this track. It flows in a great rhythm along a little canyon with some
nicely integrated jumps. Some are quite big, so better have a look when
you ride it for the first time.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/2636"
      }}>{`here`}</a>{`.`}</p>
    <Image src="destinations/granada_15.jpg" mdxType="Image" />
    <br />
    <h4>{`Shuttle`}</h4>
    <p>{`But now let's go higher to the enduro tracks of Güéjar Sierra.`}</p>
    <Image src="destinations/granada_3.jpg" mdxType="Image" />
    <br />
    <h4>{`Alguacil`}</h4>
    <p>{`Do you love rocks? If you do, you will love this track with lots of
loose and compact rocks and stones.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/2538"
      }}>{`here`}</a>{`.`}</p>
    <Image src="destinations/granada_7.jpg" mdxType="Image" />
    <br />
    <p>{`The long descent follows the hillside and passes impressive rock formations.`}</p>
    <Image src="destinations/granada_6.jpg" mdxType="Image" />
    <br />
    <h4>{`Los Álamos`}</h4>
    <p>{`The guides of Ride Sierra Nevada let it fly on the Los Álamos trail
down to the water reservoir of Güéjar Sierra. The artificial lake
supplies the city of Granada with fresh water.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/2536"
      }}>{`here`}</a>{`.`}</p>
    <Image src="destinations/granada_2.jpg" mdxType="Image" />
    <br />
    <h4>{`Granada`}</h4>
    <p>{`Granada is a city with a long history influenced by roman and arabic
culture. One of the most impressive relicts is the Kasbah Alhambra.
The palace and fortress complex is located on a hill in the city and
a must visit when you are there.`}</p>
    <Image src="destinations/granada_12.jpg" mdxType="Image" />
    <br />
    <h4>{`Earn your ride`}</h4>
    <p>{`Fire roads offer good possibilites to climb to the trails. The impressive
view onto the Sierra Nevada never gets boring.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/2624"
      }}>{`here`}</a>{`.`}</p>
    <Image src="destinations/granada_4.jpg" mdxType="Image" />
    <br />
    <h4>{`La Boca de la Pesca`}</h4>
    <p>{`The light in winter time is magic. From the Boca de la Pesca we dive into
the sunrays and a technical descent.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/2546"
      }}>{`here`}</a>{`.`}</p>
    <Image src="destinations/granada_10.jpg" mdxType="Image" />
    <br />
    <h4>{`Granada`}</h4>
    <p>{`Granada and the Sierra Nevada is a great place to combine a biking
holiday with culture and a good night life. You can ride all year long,
although the best time is in March to June and September to December.`}</p>
    <Image src="destinations/granada_16.jpg" mdxType="Image" />
    <br />
    <p>{`Open the region of Granada directly on
`}<a parentName="p" {...{
        "href": "https://trailguide.net/?pos=12,37.1332,-3.5580&map=cyclemap"
      }}>{`Trailguide`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      